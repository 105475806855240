//Swiper docs: https://swiperjs.com/swiper-api

import Swiper, { Pagination, A11y, Autoplay } from "swiper";

// init Swiper:
const swiperProduct = new Swiper(".product-slider", {
	// configure Swiper to use modules
	modules: [Pagination, A11y, Autoplay],
	spaceBetween: 10,

	autoplay: {
		delay: 5000,
	},

	pagination: {
		el: ".slider-pagination",
		clickable: true,
		type: "bullets",
		bulletClass: "slider-indicator",
		bulletActiveClass: "active",
		renderBullet: function (index, className) {
			return `<button id="slider-tab-${index}" type="button" aria-disabled="${index === 0}" ${index === 0 ? 'disabled' : ''} aria-controls="slide-${index}" class="${className}" aria-labelledby="slide-${index}"></button>`;
		},
	},

	a11y: {
		enabled: true,
		containerMessage: 'Premier Collection Products from Palm Beach Tan',
		containerRoleDescriptionMessage: 'carousel',
		itemRoleDescriptionMessage: 'slide'
	}
});

swiperProduct.on('slideChange', function () {
	swiperProduct.pagination.bullets.map((bullet, index) => {
		if(swiperProduct.activeIndex === index) {
			bullet.setAttribute('aria-disabled', true)
			bullet.disabled = true
		} else {
			bullet.setAttribute('aria-disabled', false)
			bullet.disabled = false
		}
	})
});
